export default {
  cake: { // VERTEX
    137: '0x72572CCf5208b59f4BcC14e6653d8c31Cd1fC5a0', // VERT LIVE
    80001: '0x72572CCf5208b59f4BcC14e6653d8c31Cd1fC5a0', 
  },
  masterChef: {
    137: '0x8bE82Ab9B6179bE6EB88431E3E4E0fd93b9E607C', // LIVE
    80001: '0x9cCA28d98EC895c50C266b7f138dD6316aADB52A',
  },
  wbnb: {
    137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // WMATIC
    80001: '',
  },
  lottery: {
    137: '',
    80001: '',
  },
  lotteryNFT: {
    137: '',
    80001: '',
  },
  mulltiCall: {
    137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
    80001: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc',
  },
  busd: {
    137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
    80001: '',
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // USDT
    80001: '',
  },
  wbtc: {
    137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // wbtc
    80001: '',
  },
  weth: {
    137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // weth
    80001: '',
  },

  // TIMELOCK: 0x8eC313CFbc877a9E0559c8e80763a9a058C28C69
}
